<template>
  <div>
    <v-tabs v-model="currentTab" dark background-color="primary" show-arrows>
      <v-tab
        v-for="(tab, index) in gruposSiglas"
        :key="index"
        :href="`#${tab.sigla}`"
        @click="resolvedQuestion(tab.sigla)"
      >
        {{ tab.nome }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item
        v-for="(tab, index) in gruposSiglas"
        :key="index"
        :value="`${tab.sigla}`"
      >
        <v-card flat>
          <v-card-text style="height: 22em !important" class="overflow-y-auto">
            <div
              v-for="(pergunta, index) in resolvedQuestion(tab.sigla)"
              :key="pergunta.avatar"
              :class="`d-flex ${index > 0 ? 'mt-6' : ''}`"
            >
              <div class="d-flex align-center flex-grow-1 flex-no-wrap">
                <div class="me-auto pe-2">
                  <span class="text-sm text-wrap">{{
                    pergunta.descricao
                  }}</span>
                </div>

                <div>
                  <p class="text--primary font-weight-medium mb-1">
                    {{ pergunta.favorabilidade }}%
                  </p>
                  <v-progress-linear
                    :value="pergunta.progresso"
                    :color="resolveColorVariant(pergunta.favorabilidade)"
                  ></v-progress-linear>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";

import { getVuetify } from "@core/utils";

import store from "@/store";
import { computed } from "@vue/composition-api";
import themeConfig from "@themeConfig";

export default {
  setup() {
    const $vuetify = getVuetify();
    const $themeColors = themeConfig.themes.light;
    const $chartColors = themeConfig.colors;
    let gruposSiglas = [];

    const currentTab = ref("PLA");

    const gruposData = computed(
      () => store.getters["pesquisas/getGruposPainel"]
    );

    Object.keys(gruposData.value).forEach((el) => {
      gruposSiglas.push({ sigla: el, nome: gruposData.value[el].nome_grupo });
    });

    const resolveColorVariant = (value) => {
      let numberValid = parseFloat(value.toFixed(1));
      if (numberValid <= 25) return $chartColors.red.color;
      if (numberValid <= 50) return $chartColors.yellow.color;
      if (numberValid <= 75) return $chartColors.green.color;
      if (numberValid >= 75.1 || numberValid <= 100)
        return $chartColors.white_blue.color;

      return $chartColors.white_blue.color;
    };

    const resolvedQuestion = (sigla) => {
      let objetoPerguntas = [];
      Object.keys(gruposData.value[sigla].perguntas).forEach((el) => {
        objetoPerguntas.push({
          descricao: gruposData.value[sigla].perguntas[el].descricao,
          progresso: parseInt(
            gruposData.value[sigla].perguntas[el].favorabilidade.toFixed(0)
          ),
          favorabilidade: gruposData.value[sigla].perguntas[el].favorabilidade,
        });
      });
      return objetoPerguntas;
    };

    return {
      resolveColorVariant,
      resolvedQuestion,
      gruposSiglas,
      currentTab,
    };
  },
};
</script>
