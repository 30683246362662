<template>
  <v-card elevation="0" class="mx-2 mt-2 mb-0">
    <v-row class="ma-0">
      <v-col cols="12" sm="9" class="total-profit-chart-col">
        <v-card-title class="pt-2"> Favorabilidade </v-card-title>
        <v-card-subtitle> Favorabilidade por fatores </v-card-subtitle>
        <vue-apex-charts
          id="total-profit-chart"
          height="490"
          :options="chartOptions"
          :series="chartData"
        ></vue-apex-charts>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card elevation="0">
          <v-card-title>Legenda dos Fatores</v-card-title>
          <v-card-text class="mt-2">
            <div
              v-for="(data, index) in objetoFatores"
              :key="data.title"
              :class="`statistics-table d-flex align-center pt-3 ${
                index > 0 ? 'mt-3' : ''
              }`"
            >
              <span class="text--primary ms-2">{{ data.nome }}</span>

              <v-spacer></v-spacer>
              <span
                :style="`color: ${$chartColors.white_blue.variants.var2} !important;`"
                class="font-weight-semibold me-1"
                >{{ data.sigla }}</span
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
// eslint-disable-next-line object-curly-newline
import {
  mdiDotsVertical,
  mdiTrendingUp,
  mdiCurrencyUsd,
  mdiChartBar,
} from "@mdi/js";
import { getVuetify } from "@core/utils";
import { kFormatter } from "@core/utils/filter";
import store from "@/store";
import { computed } from "@vue/composition-api";
import themeConfig from "@themeConfig";

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const $vuetify = getVuetify();
    const $themeColors = themeConfig.themes.light;
    const $chartColors = themeConfig.colors;
    let objetoFatores = [];
    let objetoSiglas = [];
    let objetoFavorabilidade = {
      DT: [],
      DP: [],
      CP: [],
      CT: [],
    };

    const gruposData = computed(
      () => store.getters["pesquisas/getGruposPainel"]
    );
    const favorabilidadeData = computed(
      () => store.getters["pesquisas/getFavorabilidadePainel"]
    );

    Object.keys(gruposData.value).forEach((el) => {
      objetoFatores.push({ sigla: el, nome: gruposData.value[el].nome_grupo });
      objetoSiglas.push(el);
    });
    Object.keys(favorabilidadeData.value).forEach((el) => {
      objetoFavorabilidade.DT.push(
        parseFloat(favorabilidadeData.value[el].DT.toFixed(2))
      );
      objetoFavorabilidade.DP.push(
        parseFloat(favorabilidadeData.value[el].DP.toFixed(2))
      );
      objetoFavorabilidade.CP.push(
        parseFloat(favorabilidadeData.value[el].CP.toFixed(2))
      );
      objetoFavorabilidade.CT.push(
        parseFloat(favorabilidadeData.value[el].CT.toFixed(2))
      );
    });

    const chartOptions = {
      colors: [
        $chartColors.white_blue.color,
        $chartColors.green.color,
        $chartColors.yellow.color,
        $chartColors.red.color,
      ],
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: "35%",
          startingShape: "rounded",
          endingShape: "rounded",
        },
      },
      xaxis: {
        categories: objetoSiglas,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => kFormatter(value, 0),
        },
      },
      grid: {
        strokeDashArray: 7,
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: "smooth",
        width: 6,
        lineCap: "round",
        colors: ["#fff"],
      },
      responsive: [
        {
          breakpoint: 1400,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: "45%",
              },
            },
          },
        },
      ],
    };

    const chartData = [
      {
        name: "Favorável",
        data: objetoFavorabilidade.CT,
      },
      {
        name: "Médio Favorável",
        data: objetoFavorabilidade.CP,
      },
      {
        name: "Médio Desfavorável",
        data: objetoFavorabilidade.DP,
      },

      {
        name: "Desfavorável",
        data: objetoFavorabilidade.DT,
      },
    ];

    return {
      chartOptions,
      $chartColors,
      chartData,
      objetoFatores,
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
        mdiChartBar,
      },
    };
  },
};
</script>

<style lang="scss">
@import "~@core/preset/preset/mixins.scss";
@import "~vuetify/src/styles/styles.sass";
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .total-profit-chart-col {
    @include ltr() {
      border-right: thin solid;
    }
    @include rtl() {
      border-left: thin solid;
    }
  }
}
@include theme--child(total-profit-chart-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, "dividers");
  }
}
.chart-wrapper {
  max-width: 130px;
}
.statistics-table {
  border-top: solid 1px rgba(93, 89, 98, 0.14);
  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin: 4px;
  }
}
</style>
