<template>
  <div>
    <v-row class="pb-0 mt-5" order="1">
      <v-col cols="12" sm="12" md="4">
        <fidelidade-statistics></fidelidade-statistics>
      </v-col>
      <v-col cols="12" md="8" sm="12" order="2">
        <favorabilidade-geral-statistics></favorabilidade-geral-statistics>
      </v-col>
      <v-col cols="12" class="mt-2" order="3">
        <favorabilidade-statistics></favorabilidade-statistics>
      </v-col>
      <v-col cols="12" sm="12" md="4" order="5">
        <favorabilidade-consolidada-statistics></favorabilidade-consolidada-statistics>
      </v-col>
      <v-col cols="12" md="8" sm="12" order="6">
        <v-card elevation="3" outlined class="chart-card-total-visitors d-flex flex-column mx-6 mb-2 mt-0">
          <v-card-title class="align-start">
            <span>Favorabilidade Consolidada</span>            
          </v-card-title>
          <v-card-subtitle>Por fatores e perguntas</v-card-subtitle>
          <favorabilidade-consolidade-perguntas></favorabilidade-consolidade-perguntas>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import FidelidadeStatistics from "./favorabilidade/FidelidadeStatistics.vue";
import FavorabilidadeGeralStatistics from "./favorabilidade/FavorabilidadeGeralStatistics.vue";
import FavorabilidadeStatistics from "./favorabilidade/FavorabilidadeStatistics.vue";
import FavorabilidadeConsolidadaStatistics from "./favorabilidade/FavorabilidadeConsolidadaStatistics.vue";
import FavorabilidadeConsolidadePerguntas from './favorabilidade/FavorabilidadeConsolidadePerguntas.vue';


export default {
  components: {
    FidelidadeStatistics,
    FavorabilidadeGeralStatistics,
    FavorabilidadeStatistics,
    FavorabilidadeConsolidadaStatistics,
    FavorabilidadeConsolidadePerguntas
  },

  setup() {
    return {

    };
  },
};
</script>

<style>
</style>