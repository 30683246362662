<template>
  <v-card elevation="3" outlined class="ma-6">
    <v-card-title class="align-start">
      <span>Fidelidade</span>
      <v-spacer></v-spacer>
      <v-btn icon small class="me-n3 mt-n2">
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="d-flex align-center justify-space-between pb-1 pt-5">
      <div>
        <p class="text-5xl font-weight-semibold text--primary mb-2">
          {{ fidelidadeData.nps.toFixed(1) }}
        </p>
        <span class="text-base">Nota NPS</span>
      </div>
    </v-card-text>

    <v-card-text class="mt-2">
      <div
        v-for="(data, index) in favStatistics"
        :key="data.title"
        :class="`statistics-table d-flex align-center pt-3 ${
          index > 0 ? 'mt-3' : ''
        }`"
      >
        <span
          :style="`background-color: ${data.color} !important; border-color: ${data.color} !important`"
          class="badge-sm"
        ></span>
        <span class="text--primary ms-2">{{ data.title }}</span>

        <v-spacer></v-spacer>
        <span class="font-weight-semibold text--primary me-1"
          >{{ data.percentage }}%</span
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from "@mdi/js";

import { getVuetify } from "@core/utils";

import store from "@/store";
import { computed } from "@vue/composition-api";
import themeConfig from "@themeConfig";

export default {
  setup() {
    const $vuetify = getVuetify();
    const $chartColors = themeConfig.colors;

    const chartOptions = {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      grid: {
        show: false,
      },
      colors: [$vuetify.theme.currentTheme.primary],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            size: "65%",
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              fontSize: "1.25rem",
              fontWeight: "600",
              offsetY: 0,
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
    };

    const fidelidadeData = computed(
      () => store.getters["pesquisas/getFidelidadePainel"]
    );

    const favStatistics = [
      {
        title: "Favorável",
        color: $chartColors.white_blue.color,
        percentage: fidelidadeData.value.mdTotal.CT.toFixed(1),
      },
      {
        title: "Médio favorável",
        color: $chartColors.green.color,
        percentage: fidelidadeData.value.mdTotal.CP.toFixed(1),
      },
      {
        title: "Médio desfavorável",
        color: $chartColors.yellow.color,
        percentage: fidelidadeData.value.mdTotal.DP.toFixed(1),
      },
      {
        title: "Desfavorável",
        color: $chartColors.red.color,
        percentage: fidelidadeData.value.mdTotal.DT.toFixed(1),
      },
    ];

    return {
      fidelidadeData,

      chartOptions,

      favStatistics,

      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.statistics-table {
  border-top: solid 1px rgba(93, 89, 98, 0.14);
  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin: 4px;
  }
}
</style>
