import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"ma-6",attrs:{"elevation":"3","outlined":""}},[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Fidelidade")]),_c(VSpacer),_c(VBtn,{staticClass:"me-n3 mt-n2",attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,{staticClass:"d-flex align-center justify-space-between pb-1 pt-5"},[_c('div',[_c('p',{staticClass:"text-5xl font-weight-semibold text--primary mb-2"},[_vm._v(" "+_vm._s(_vm.fidelidadeData.nps.toFixed(1))+" ")]),_c('span',{staticClass:"text-base"},[_vm._v("Nota NPS")])])]),_c(VCardText,{staticClass:"mt-2"},_vm._l((_vm.favStatistics),function(data,index){return _c('div',{key:data.title,class:("statistics-table d-flex align-center pt-3 " + (index > 0 ? 'mt-3' : ''))},[_c('span',{staticClass:"badge-sm",style:(("background-color: " + (data.color) + " !important; border-color: " + (data.color) + " !important"))}),_c('span',{staticClass:"text--primary ms-2"},[_vm._v(_vm._s(data.title))]),_c(VSpacer),_c('span',{staticClass:"font-weight-semibold text--primary me-1"},[_vm._v(_vm._s(data.percentage)+"%")])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }