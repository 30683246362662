<template>
  <v-card elevation="0" class="ma-2">
    <v-card-title class="align-start">
      <span>Favorabilidade Geral</span>
      <v-spacer></v-spacer>

      <v-btn icon small class="mt-n2 me-n3">
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <vue-apex-charts
            id="chart-sales-overview"
            :options="chartOptions"
            :series="chartData"
            class="h-full d-flex align-center"
          ></vue-apex-charts>
        </v-col>

        <v-col cols="12" sm="6" class="d-flex flex-column justify-center">
          <div class="d-flex align-center">
            <v-avatar class="v-avatar-light-bg primary--text" rounded size="40">
              <v-icon size="30" color="primary">
                mdi-alert-circle-outline
              </v-icon>
            </v-avatar>
            <div class="ms-4 d-flex flex-column">
              <p class="text--primary mb-0 text-base">
                Total Favorabilidade Geral
              </p>
              <span class="text--primary font-weight-semibold text-xl"
                >{{ favTotal }}%</span
              >
            </div>
          </div>

          <v-divider class="my-6"></v-divider>
          <table class="sales-overview-stats-table">
            <tr>
              <td>
                <div class="mb-0">
                  <div
                    :style="`background-color: ${$chartColors.white_blue.color} !important; border-color: ${$chartColors.white_blue.color} !important`"
                    class="stats-dot d-inline-block rounded-circle me-2"
                  ></div>
                  <span>Favorável</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4"
                  >{{ chartData[0] }}%</span
                >
              </td>
              <td>
                <div class="mb-0">
                  <div
                    :style="`background-color: ${$chartColors.green.color} !important; border-color: ${$chartColors.green.color} !important`"
                    class="stats-dot d-inline-block rounded-circle me-2"
                  ></div>
                  <span>Médio favorável</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4"
                  >{{ chartData[1] }}%</span
                >
              </td>
            </tr>
            <tr>
              <td>
                <div class="mb-0">
                  <div
                    :style="`background-color: ${$chartColors.yellow.color} !important; border-color: ${$chartColors.yellow.color} !important`"
                    class="stats-dot d-inline-block rounded-circle me-2"
                  ></div>
                  <span>Médio desfavorável</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4"
                  >{{ chartData[2] }}%</span
                >
              </td>
              <td>
                <div class="mb-0">
                  <div
                    :style="`background-color: ${$chartColors.red.color} !important; border-color: ${$chartColors.red.color} !important`"
                    class="stats-dot d-inline-block rounded-circle me-2"
                  ></div>
                  <span>Desfavorável</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4"
                  >{{ chartData[3] }}%</span
                >
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiCurrencyUsd } from "@mdi/js";
import { getVuetify } from "@core/utils";

import store from "@/store";
import { computed } from "@vue/composition-api";
import themeConfig from "@themeConfig";

export default {
  components: {
    VueApexCharts: () => import("vue-apexcharts"),
  },
  setup() {
    const $vuetify = getVuetify();
    const $chartColors = themeConfig.colors;

    const favorabilidadeGeralData = computed(
      () => store.getters["pesquisas/getFavorabilidadeGeralPainel"]
    );

    const chartOptions = {
      labels: [
        "Favorável",
        "Médio Favorável",
        "Médio Desfavorável",
        "Desfavorável",
      ],
      colors: [
        $chartColors.white_blue.color,
        $chartColors.green.color,
        $chartColors.yellow.color,
        $chartColors.red.color,
      ],
      chart: {
        type: "donut",
        sparkline: {
          enabled: false,
        },
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
            labels: {
              show: true,
              name: {
                fontSize: "14px",
                offsetY: 25,
              },
              value: {
                fontSize: "2.125rem",
                fontWeight: 600,
                offsetY: -15,
                formatter(value) {
                  return `${value}%`;
                },
              },
            },
          },
        },
      },
    };

    const chartData = [
      parseFloat(favorabilidadeGeralData.value.CT.toFixed(2)),
      parseFloat(favorabilidadeGeralData.value.CP.toFixed(2)),
      parseFloat(favorabilidadeGeralData.value.DP.toFixed(2)),
      parseFloat(favorabilidadeGeralData.value.DT.toFixed(2)),
    ];
    const favTotal = parseFloat(favorabilidadeGeralData.value.total.toFixed(2));
    return {
      chartOptions,
      $chartColors,
      chartData,
      favTotal,
      icons: {
        mdiDotsVertical,
        mdiCurrencyUsd,
      },
    };
  },
};
</script>

<style lang="scss">
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }
}
</style>
