<template>
  <v-card elevation="0" class="chart-card-total-visitors d-flex flex-column mx-2 mb-2 mt-0">
    <v-card-title class="align-start">
      <span>Favorabilidade Consolidada</span>
    </v-card-title>
    <v-card-subtitle>Geral</v-card-subtitle>

    <vue-apex-charts
      id="chart-total-visitors"
      :options="chartOptions"
      :series="favorabilidadeGrupoTotal"
      class="d-flex flex-grow-1 align-center mb-4"
    ></vue-apex-charts>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from "@mdi/js";
import VueApexCharts from "vue-apexcharts";
import { getVuetify, addAlpha } from "@core/utils";

import store from "@/store";
import { computed } from "@vue/composition-api";
import themeConfig from "@themeConfig";

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const $vuetify = getVuetify();
    const $chartColors = themeConfig.colors;
    let favorabilidadeGrupoTotal = [];
    let gruposSiglas = [];

    const favorabilidadeData = computed(
      () => store.getters["pesquisas/getFavorabilidadePainel"]
    );

    function selectColorBasedOnData(value) {
      let numberValid = parseFloat(value.toFixed(1));
      if (numberValid <= 25) return $chartColors.red.color;
      if (numberValid <= 50) return $chartColors.yellow.color;
      if (numberValid <= 75) return $chartColors.green.color;
      if (numberValid >= 75.1 || numberValid <= 100)
        return $chartColors.white_blue.color;

      return $chartColors.white_blue.color;
    }

    Object.keys(favorabilidadeData.value).forEach((el) => {
      favorabilidadeGrupoTotal.push(
        parseFloat(favorabilidadeData.value[el].total.toFixed(2))
      );
      gruposSiglas.push(el);
    });

    const chartOptions = {
      colors: $chartColors.yellow.variants.gradient1,
      chart: {
        type: "pie",
      },
      legend: {
        show: true,
        position: "bottom",
      },
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
      },
      labels: gruposSiglas,
      tooltip: {
        enabled: true,
      },
    };
    return {
      chartOptions,

      favorabilidadeGrupoTotal,
      gruposSiglas,

      icons: {
        mdiDotsVertical,
      },
    };
  },
};
</script>

<style lang="scss">
@import "~@core/preset/preset/mixins.scss";

#chart-total-visitors {
  .apexcharts-legend {
    padding: 0;
  }
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}
</style>
