import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-2 mt-2 mb-0",attrs:{"elevation":"0"}},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"total-profit-chart-col",attrs:{"cols":"12","sm":"9"}},[_c(VCardTitle,{staticClass:"pt-2"},[_vm._v(" Favorabilidade ")]),_c(VCardSubtitle,[_vm._v(" Favorabilidade por fatores ")]),_c('vue-apex-charts',{attrs:{"id":"total-profit-chart","height":"490","options":_vm.chartOptions,"series":_vm.chartData}})],1),_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,[_vm._v("Legenda dos Fatores")]),_c(VCardText,{staticClass:"mt-2"},_vm._l((_vm.objetoFatores),function(data,index){return _c('div',{key:data.title,class:("statistics-table d-flex align-center pt-3 " + (index > 0 ? 'mt-3' : ''))},[_c('span',{staticClass:"text--primary ms-2"},[_vm._v(_vm._s(data.nome))]),_c(VSpacer),_c('span',{staticClass:"font-weight-semibold me-1",style:(("color: " + (_vm.$chartColors.white_blue.variants.var2) + " !important;"))},[_vm._v(_vm._s(data.sigla))])],1)}),0)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }